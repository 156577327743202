import { useEffect } from "react";
import { fetchAppointmentsLineChart, fetchAppointmentstPieChart } from "../../services/appointments";
import { useBaseQueryVariables } from "./useBaseQueryVariables";

export const useFetchAppointmentsData = () => {

  const { filters, tokenFound, dispatch } = useBaseQueryVariables();

  useEffect(() => {
    if (tokenFound) {
      dispatch(fetchAppointmentsLineChart);
      dispatch(fetchAppointmentstPieChart);
    }
  }, [dispatch, filters, tokenFound]);
}