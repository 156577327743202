import { createSlice } from "@reduxjs/toolkit";
import { GlobalData, GlobalPageUsedFilters } from "../utils/types";

interface Istate {
  data: GlobalData | {},
  status: string;
  error: any;
  filters: GlobalPageUsedFilters | null;
}

const initialState: Istate = {
  data: {},
  status: "void",
  error: null,
  filters: null,
}

const { actions, reducer } = createSlice({
  name: 'global',
  initialState,
  reducers: {
    fetching: (draft) => {
      if (draft.status === 'pending') {
        return;
      }
      draft.status = "pending";
      draft.error = null;
      return;
    },
    resolved: (draft, action: { type: string, payload: { filters: GlobalPageUsedFilters, data: GlobalData } }) => {
      draft.status = "resolved";
      draft.data = action.payload.data;
      draft.filters = action.payload.filters;
      return;
    },
    rejected: (draft, action) => {
      draft.status = "rejected";
      draft.data = {};
      draft.filters = null;
      draft.error = action.payload;
      return;
    }
  }
})

export const { fetching, resolved, rejected } = actions;
export default reducer;