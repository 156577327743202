import { Grid } from "@mui/material";
import { useSelector } from "react-redux";
import { selectFilters, selectGlobalData, selectUserHasGoal, selectUserIsTitulaire } from "../../utils/selectors";
import { StyledBigNumber, StyledContainer, StyledGoal, StyledGoalDescription, StyledGrid } from "./KeyData.styles";
export const KeyData = () => {

  const data = useSelector(selectGlobalData);
  const { period } = useSelector(selectFilters);
  const isTitulaire = useSelector(selectUserIsTitulaire);
  const hasGoals = useSelector(selectUserHasGoal);
  const renderGoals = isTitulaire && hasGoals && period;

  const calch = (time: number) => Math.trunc((time || 0) / 60);
  const calcmin = (time: number) => (time || 0) % 60;
  const globalMinutesCalc = calcmin(data?.time?.global);
  const globalGoalMinutesCalc = calcmin(data?.time?.global_goal);

  const globalTime = {
    hours: calch(data?.time?.global),
    minutes: globalMinutesCalc < 10 ? `0${globalMinutesCalc}` : globalMinutesCalc,
  };

  const goalTime = {
    hours: calch(data?.time?.global_goal),
    minutes: globalGoalMinutesCalc < 10 ? `0${globalGoalMinutesCalc}` : globalGoalMinutesCalc,
  };

  const items = [{
    value: data?.patients?.global,
    goal: data?.patients?.global_goal,
    description: <StyledGoalDescription>Patients Suivis</StyledGoalDescription>,
    shouldRender: true
  }, {
    value: data?.appointments?.global,
    goal: data?.appointments?.global_goal,
    description: <StyledGoalDescription>Accompagnements Réalisés</StyledGoalDescription>,
    shouldRender: true
  }, {
    value: `${globalTime.hours}h${globalTime.minutes}`,
    goal: `${goalTime.hours}h${goalTime.minutes}`,
    description: period ? <StyledGoalDescription>Temps passé en moyenne<br /> par semaine</StyledGoalDescription> : <StyledGoalDescription>Temps par semaine </StyledGoalDescription>,
    shouldRender: true,
  }, {
    value: `${data?.remuneration?.global}€`,
    goal: data?.remuneration?.global_goal,
    description: <StyledGoalDescription>Rémunération Estimée</StyledGoalDescription>,
    shouldRender: isTitulaire,
  }]

  return (
    <StyledContainer container istitulaire={isTitulaire ? 'true' : 'false'} >
      {items.map((entry, index) => (
        entry.shouldRender && (
          <Grid item container sm={3} key={`keydata-${index}`} style={{ display: 'flex', justifyContent: "center" }}>
            {entry.description}
            <StyledGrid item xs={12} key={`global-${index}`} sx={{ alignItems: 'end', justifyContent: 'center' }}>
              <StyledBigNumber fontSize={{ xs: 24, sm: 28, md: 36, lg: 50 }}>{entry.value}</StyledBigNumber>
              {renderGoals && <StyledGoal fontSize={{ xs: 16, md: 20, lg: 24 }}>/{entry.goal}</StyledGoal>}
            </StyledGrid>
          </Grid>
        )
      ))}
    </StyledContainer >
  )
}