import styled from "@emotion/styled";
import { Grid, Typography } from "@mui/material";
import contents from "../../contents.json";
import theme from '../../theme';

// Emotions throwing console error if using boolean value
export const StyledContainer = styled(Grid) <{ istitulaire: string }>`
  justify-content: ${props => props.istitulaire === "true" ? 'space-around' : 'center'};
  flex-wrap: wrap;
  padding-bottom: 15px;
  padding-top: 15px;
`;

export const StyledGrid = styled(Grid)`
  display: flex;
`;

export const StyledBigNumber = styled(Typography)`
  color: ${theme.palette.secondary.main};
  font-weight: 700;
  margin-top: 0;
`;

export const StyledGoal = styled(Typography)`
  margin-bottom: 0;
  margin-top:30px;
  color: ${theme.palette.primary.main};
  font-weight: 900;
`;

export const StyledGoalDescription = styled(Typography)`
  margin-left: 15px;
  margin-bottom: 0;
  text-align: center;
  color: ${contents.globals.descriptionColor};
  font-weight: 700;
  text-transform: uppercase;
`;