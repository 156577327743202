import React from 'react';
import { useSelector } from 'react-redux';
import contents from '../../contents.json';
import { selectUserIsTitulaire } from '../../utils/selectors';
import { StyledBoxNav, StyledTab, StyledTabs, tabIndicatorStyle } from './VerticalTab.styles';

type Props = {
  value: number;
  handleChange: (event: React.SyntheticEvent, newValue: number) => void;
};

export const VerticalTab = ({ value, handleChange }: Props) => {

  const titulaire = useSelector(selectUserIsTitulaire);
  const arrayNav = titulaire ? contents.tabs_owner : contents.tabs_member;

  return (
    <StyledBoxNav>
      <StyledTabs value={value} onChange={handleChange} orientation="vertical" TabIndicatorProps={{ style: tabIndicatorStyle }}>
        {arrayNav.map((tab: any, i: number) => (
          <StyledTab key={i} aria-label={tab} label={tab} sx={{ zIndex: '1' }} />
        ))}
      </StyledTabs>
    </StyledBoxNav>
  );
};
