import { useSelector } from 'react-redux';
import contents from '../../contents.json';
import { selectFilters, selectUserHasGoal, selectUserIsTitulaire } from '../../utils/selectors';
import { GlobalCardData } from '../../utils/types';
import { CardHeader } from '../CardHeader';
import { ProgressBar } from '../ProgressBar/ProgressBar';
import { StyledBoxCard, StyledBoxMain, StyledTitleCard } from './GlobalCard.styles';

type Props = {
  color: string;
  title: string;
  details: boolean;
  data: GlobalCardData;
};

export const GlobalCard = ({ color, title, details, data }: Props) => {

  const { period } = useSelector(selectFilters);
  const isTitulaire = useSelector(selectUserIsTitulaire);
  const hasGoal = useSelector(selectUserHasGoal);
  const progressRender = period && isTitulaire && hasGoal;

  return (
    <StyledBoxCard>
      <StyledTitleCard variant="h5" color={color}>
        {title}
      </StyledTitleCard>
      <CardHeader color={color} number={data.patients_goal} details={progressRender} />
      <StyledBoxMain>
        <ProgressBar color={color} amount={data.patients_current} title={contents.globals.patients} total={details ? data.patients_goal || 0 : 0} amountOf={data.patients_current > 1 ? 'patients' : 'patient'} hasGoal={period} />
        <ProgressBar color={color} amount={data.appointments_current} title={contents.globals.appointments} total={details ? data.appointments_goal || 0 : 0} amountOf={data.appointments_current > 1 ? "entretiens" : "entretien"} hasGoal={period} />
        {progressRender && <ProgressBar color={color} amount={data.remuneration_current || 0} title={contents.globals.remuneration} total={details ? data.remuneration_goal || 0 : 0} money={true} amountOf="€" hasGoal={period} />}
      </StyledBoxMain>
    </StyledBoxCard>
  );
};
