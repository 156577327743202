import { Typography } from "@mui/material";
import { Cell, Pie, PieChart as RePieChart, ResponsiveContainer } from "recharts";
import { useResizeTracker } from "../../hooks/useResizeTracker";
import { StyledPieContainer } from "./PieChart.styles";
import { PieChartLegend } from "./PieChartLegend";

type PieDataObject = {
  name: string;
  value: number | string;
  color?: string;
}

type PieChartProps = {
  title?: string;
  data: PieDataObject[];
  colors: string[];
  isTime?: boolean;
}

export const PieChart = ({ title, data, colors, isTime = false }: PieChartProps) => {

  const size = useResizeTracker();
  const sum = (data || []).reduce((acc, e) => acc += e.value !== 0 ? 1 : 0, 0);

  if (sum === 0) {
    return null;
  }

  return (
    <StyledPieContainer>
      {title && (
        <Typography component='h2' variant="h2" color='primary' px={2}>
          {title}
        </Typography>
      )}
      <ResponsiveContainer width='100%' height={220} key={size}>
        <RePieChart>
          <Pie
            data={data}
            innerRadius={65}
            outerRadius={75}
            startAngle={135}
            cx="50%"
            cy={100}
            endAngle={495}
            dataKey="value"
          >
            {(data || []).map((_, index) => (
              <Cell key={`cell-${index}`} fill={data[index]?.color || colors[index]} />
            ))}
          </Pie>
        </RePieChart>
      </ResponsiveContainer>
      <PieChartLegend data={data} colors={colors} isTime={isTime} />
    </StyledPieContainer>
  );
};