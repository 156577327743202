import { Box, Typography } from "@mui/material";
import { useSelector } from "react-redux";
import {
  CartesianGrid, Legend, Line, LineChart as ReLineChart, ResponsiveContainer, Tooltip, XAxis,
  YAxis
} from "recharts";
import contents from "../../contents.json";
import { useResizeTracker } from "../../hooks/useResizeTracker";
import { selectNavPage } from '../../utils/selectors';
import { LineData } from "../../utils/types";
import { countUniqueValues, hourformat, minuteformat } from "../../utils/utils";
import { LineChartViewSelector } from "../LineChartViewSelector";
import { Loader } from "../Loader/Loader";
import { RequestError } from "../RequestError/RequestError";
import { StyledTooltipCircle, StyledTooltipName, StyledTooltipValue, styles } from "./LineChart.styles";
import { LineChartLegend } from "./LineChartLegend";
import { lines } from './linetype';

type LineChartProps = {
  title: string;
  data: LineData[];
  error: string;
  status: string;
}

export const LineChart = ({ title, data, error, status }: LineChartProps) => {

  const size = useResizeTracker();
  const numberOfLines = countUniqueValues(data);
  const tNumber = numberOfLines < 5 ? Math.max(1, numberOfLines) : 5;
  const tickformat = (tick: any) => typeof tick === "number" ? String(Math.ceil(isTime ? tick / 60 : tick)) : tick;
  const page = useSelector(selectNavPage);
  const isTime = page === "time";
  const isRemuneration = page === "remuneration";

  const tooltipFormat = (value: string | number, name: string, props: any) => {
    const formatValue = isTime ? `${hourformat(value as number)}h${minuteformat(value as number)}` : isRemuneration ? `${value}€` : value;
    return (
      [
        <Typography sx={styles.tooltip.itemFormat} color='primary'>
          <StyledTooltipCircle backColor={props.color} />
          <StyledTooltipValue>{formatValue}</StyledTooltipValue>
          <StyledTooltipName>{name === "posology" ? "posologie" : name}</StyledTooltipName>
        </Typography>
      ]
    );
  }

  if (status === "rejected") {
    return <RequestError error={error} />
  }

  if (status === "pending") {
    return <Loader />
  }

  return (
    <Box pl={3}>
      <Box sx={{ position: 'relative', width: '90%' }}>
        <Typography component="h3" variant='h2' my={2} color="primary">{title}</Typography>
        <LineChartViewSelector />
      </Box>
      <ResponsiveContainer width="90%" height={400} key={size}>
        <ReLineChart data={data}>
          <Legend verticalAlign="top" content={<LineChartLegend remuneration={isRemuneration} />} />

          <CartesianGrid vertical={false} stroke='rgb(230,230,230)' />
          <XAxis dataKey="abs" axisLine={false} stroke={contents.lineChart.color.axisStroke} />
          <YAxis tickCount={tNumber} tickFormatter={tick => tickformat(tick)} axisLine={false} stroke={contents.lineChart.color.axisStroke} />

          <Tooltip
            itemStyle={styles.tooltip.itemStyle}
            separator=" "
            formatter={tooltipFormat}
            wrapperStyle={styles.tooltip.wrapperStyle}
            contentStyle={styles.tooltip.contentStyle}
            labelStyle={styles.tooltip.labelStyle}
            labelFormatter={(label: any) => `${label}`}
          />

          {lines.map((line, index) => (
            <Line
              key={`line-${index}`}
              strokeWidth={3}
              type="linear"
              dataKey={line.dataKey}
              stroke={line.stroke}
              activeDot={{ r: 4 }}
              dot={{ strokeWidth: 6 }}
            />
          ))}
        </ReLineChart>
      </ResponsiveContainer>
    </Box>
  );
}