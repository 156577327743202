import { useEffect } from "react";
import { fetchPatientLineChart, fetchPatientPieChart } from "../../services/patients";
import { useBaseQueryVariables } from "./useBaseQueryVariables";

export const useFetchPatientData = () => {
  const { filters, tokenFound, dispatch } = useBaseQueryVariables();

  useEffect(() => {
    if (tokenFound) {
      dispatch(fetchPatientLineChart);
      dispatch(fetchPatientPieChart);
    }
  }, [dispatch, filters, tokenFound]);
}