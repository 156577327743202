import { Button, Dialog, Grid } from "@mui/material";
import { Box } from "@mui/system";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import contents from '../../contents.json';
import { setEndDate, setStartDate } from "../../features/filters";
import { selectFilters } from "../../utils/selectors";
import { StaticDatePicker } from "../StaticDatePicker";

type PickersModalProps = {
  setOpen: (x: boolean) => void;
  open: boolean;
}

export const PickersModal = ({ setOpen, open }: PickersModalProps) => {

  const dispatch = useDispatch();
  const { startDate, endDate } = useSelector(selectFilters);
  const [localStartDate, setLocalStartDate] = useState(new Date(startDate));
  const [localEndDate, setLocalEndDate] = useState(new Date(endDate));

  const maxDate = new Date();
  maxDate.setDate(maxDate.getDate() - 1);

  const onConfirmation = () => {
    dispatch(setStartDate(localStartDate?.toISOString().split('T')[0]));
    dispatch(setEndDate(localEndDate?.toISOString().split('T')[0]));
    setOpen(false);
  }

  const onCancel = () => setOpen(false);

  return (
    <Dialog
      open={open}
      maxWidth='md'
    >
      <Grid container sx={{ justifyContent: 'space-between', width: "100%" }} px={2}>
        <Grid item xs={6}>
          <StaticDatePicker
            title={"Date de début"}
            isMin
            val={localStartDate}
            max={localEndDate}
            change={setLocalStartDate}
          />
        </Grid>
        <Grid item xs={6}>
          <StaticDatePicker
            title={"Date de fin"}
            val={localEndDate}
            min={localStartDate}
            max={maxDate}
            change={setLocalEndDate}
          />
        </Grid>
      </Grid>

      <Box sx={{ textAlign: 'end' }} pr={3} pb={3}>
        <Button
          onClick={onConfirmation}
          variant="contained"
          sx={{ marginRight: "20px", fontSize: 14 }}
        >
          {contents.filters.confirm}
        </Button>
        <Button
          onClick={onCancel}
          variant="outlined"
          sx={{ fontSize: 14 }}
        >
          {contents.filters.cancel}
        </Button>
      </Box>
    </Dialog>
  )
}