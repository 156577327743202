import { useSelector } from "react-redux";
import { selectRemunerationGoal, selectRemunerationLineData, selectRemunerationLineStatus } from "../utils/selectors";

export const useRemunerationData = () => {
  const lineData = useSelector(selectRemunerationLineData);
  const lineStatus = useSelector(selectRemunerationLineStatus);
  const keydata = useSelector(selectRemunerationGoal);

  return ({
    lineData, lineStatus, keydata
  });
}