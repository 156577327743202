import { useDispatch } from "react-redux";
import { StyledInputContainer, StyledSelect } from "./Filters.styles";

type OptionsArray = {
  print: string;
  value: string;
  disabled?: string;
}

type SelectProps = {
  changefn: any;
  options: OptionsArray[];
  disabled?: boolean;
}

export const Select = ({ changefn, options, disabled = false }: SelectProps) => {

  const dispatch = useDispatch();
  return (
    <StyledInputContainer>
      <StyledSelect disabled={disabled} onChange={(e) => dispatch(changefn(e.target.value))}>
        {options.map((val, i) => (
          <option key={`${val.value}-${i}`} value={val.value} disabled={Boolean(val?.disabled)}>{val.print}</option>
        ))}
      </StyledSelect>
    </StyledInputContainer>
  )
}