import styled from '@emotion/styled';
import { Box, Tab, Tabs } from '@mui/material';
import theme from '../../theme';

export const StyledTab = styled(Tab)`
  font-size: 14px;
  color: #849dc9;
  font-weight: bold;
  min-height: 30px;
  height: 30px;
  &.Mui-selected {
    color: white;
  }
  &.MuiButtonBase-root {
    align-items: start;
    padding-left: 13px;
  }
`;
export const StyledBoxNav = styled(Box)`
  width: 170px;
  text-align: flex-start;
`;
export const StyledTabs = styled(Tabs)`
  background-color: #eef1f9;
  width: 170px;
  height: 100vh;
  border-right: 1px solid #dbe1ef;
  padding-top: 13px;
`;
export const tabIndicatorStyle = { background: theme.palette.secondary.main, width: '100%', zIndex: '0', borderRadius: '0px 15px 15px 0px' };
