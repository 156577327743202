import { GlobalCardData, GlobalData, GoalPanelData, LineData, PieData, TFilters } from "./types";

export const selectGlobal = (state: any) => state.global;
export const selectGlobalData: (state: any) => GlobalData = (state: any) => state.global.data;
export const selectGlobalDataBPM: (state: any) => GlobalCardData = (state: any) => (
  {
    patients_goal: state?.global?.data?.patients?.bpm_goal,
    patients_current: state?.global?.data?.patients?.bpm,
    appointments_goal: state?.global?.data?.appointments?.bpm_goal,
    appointments_current: state?.global?.data?.appointments?.bpm,
    remuneration_goal: state?.global?.data?.remuneration?.bpm_goal,
    remuneration_current: state?.global?.data?.remuneration?.bpm
  }
);

export const selectGlobalDataChimio: (state: any) => GlobalCardData = (state: any) => (
  {
    patients_goal: state?.global?.data?.patients?.chimio_goal,
    patients_current: state?.global?.data?.patients?.chimio,
    appointments_goal: state?.global?.data?.appointments?.chimio_goal,
    appointments_current: state?.global?.data?.appointments?.chimio,
    remuneration_goal: state?.global?.data?.remuneration?.chimio_goal,
    remuneration_current: state?.global?.data?.remuneration?.chimio
  }
);

export const selectGlobalStatus: (state: any) => string = (state: any) => state.global.status;
export const selectUserHasGoal: (state: any) => boolean = (state: any) => state.global.data.hasGoal;
export const selectFilters: (state: any) => TFilters = (state: any) => state.filters;
export const selectUserIsTitulaire: (state: any) => boolean = (state: any) => state.global.data?.isTitulaire;

/** Patients page */
export const selectPatientsPieStatus: (state: any) => string = (state: any) => state.patients.pie_status;
export const selectPatientstLineStatus: (state: any) => string = (state: any) => state.patients.line_status;

export const selectPatientsPieData: (state: any) => { p1: { data: PieData[] }, p2: { data: PieData[] } } = (state: any) => state.patients.pie_data;
export const selectPatientsLineData: (state: any) => LineData[] = (state: any) => state.patients.line_data?.line;
export const selectPatientsGoal: (state: any) => GoalPanelData = (state: any) => state.patients.line_data?.keydata;

export const selectPatientLineFilters: (state: any) => TFilters = (state: any) => state.patients.line_filters;
export const selectPatientPieFilters: (state: any) => TFilters = (state: any) => state.patients.pie_filters;
/** Appointments page */
export const selectAppointmentsLineStatus: (state: any) => string = (state: any) => state.appointments.line_status;
export const selectAppointmentsPieStatus: (state: any) => string = (state: any) => state.appointments.pie_status;

export const selectAppointmentsPieData: (state: any) => { p1: { data: PieData[] }, p2: { data: PieData[] } } = (state: any) => state.appointments.pie_data;
export const selectAppointmentsLineData: (state: any) => LineData[] = (state: any) => state.appointments.line_data?.line;
export const selectAppointmentsGoal: (state: any) => GoalPanelData = (state: any) => state.appointments.line_data?.keydata;


export const selectAppointmentsLineFilters: (state: any) => TFilters = (state: any) => state.appointments.line_filters;
export const selectAppointmentsPieFilters: (state: any) => TFilters = (state: any) => state.appointments.pie_filters;

/** Time Page */
export const selectTimeLineStatus: (state: any) => string = (state: any) => state.time.line_status;
export const selectTimePieStatus: (state: any) => string = (state: any) => state.time.pie_status;
export const selectTimeGoal: (state: any) => GoalPanelData = (state: any) => state.time.line_data?.keydata;

export const selectTimePieData: (state: any) => { p1: { data: PieData[] }, p2: { data: PieData[] } } = (state: any) => state.time.pie_data;
export const selectTimeLineData: (state: any) => LineData[] = (state: any) => state.time.line_data?.line;

export const selectTimeLineFilters: (state: any) => TFilters = (state: any) => state.time.line_filters;
export const selectTimePieFilters: (state: any) => TFilters = (state: any) => state.time.pie_filters;

/** Remuneration Page */
export const selectRemunerationLineStatus: (state: any) => string = (state: any) => state.remuneration.line_status;
export const selectRemunerationLineData: (state: any) => LineData[] = (state: any) => state.remuneration.line_data?.line;
export const selectRemunerationLineFilters: (state: any) => TFilters = (state: any) => state.remuneration.line_filters;
export const selectRemunerationGoal: (state: any) => GoalPanelData = (state: any) => state.remuneration.line_data?.keydata;

/** Nav */
export const selectNavPage: (state: any) => string = (state: any) => state.navigation.page;
export const selectTokenFound: (state: any) => boolean = (state: any) => state.navigation.tokenFound;