/*
* Since there is no react router used in the app, only tabs, we use a global state 
* to keep track of current page, because this has an impact on available filters
*/

import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  page: "global",
  tokenFound: false,
}

const { actions, reducer } = createSlice({
  name: "navigation",
  initialState,
  reducers: {
    changePage: (draft, action) => {
      if (draft.page !== action.payload) {
        draft.page = action.payload;
      }
    },
    setTokenFound: (draft, action) => {
      draft.tokenFound = action.payload
    }
  }
})

export const { changePage, setTokenFound } = actions;
export default reducer;