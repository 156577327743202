import contents from '../contents.json';
import { setEndDate, setStartDate } from '../features/filters';
import { fetching, rejected, resolved } from "../features/global";
import { selectFilters, selectGlobal } from "../utils/selectors";
import { GlobalPageUsedFilters, TFilters } from "../utils/types";
import { fetchGetData } from "../utils/utils";

export const fetchGlobal = async (dispatch: any, getState: any) => {

  const status = selectGlobal(getState()).status;
  const globalPageFilters = selectGlobal(getState()).filters; // Global Page filter section when last fetched
  const { startDate, endDate, period } = selectFilters(getState()); // Filters used to send new request
  const storeFilters: GlobalPageUsedFilters = { startDate, endDate, period }

  // Do not refetch if request still pending or it has already been made without any filter change
  if (status === 'pending' || areGlobalFiltersEqual(storeFilters, globalPageFilters)) {
    return;
  }
  dispatch(fetching());
  try {
    const path = contents.paths.global;
    const response = await fetchGetData(path, storeFilters as TFilters, "global");
    const data = await response.json();

    if (response.status === 200) {
      data.global.isTitulaire = isTitulaire(data.global.roles);
      dispatch(resolved({ filters: storeFilters, data: data.global }));
      if (data?.objectiveDate) {
        dispatch(setEndDate(data.objectiveDate?.end));
        dispatch(setStartDate(data.objectiveDate?.start));
      }
    } else {
      dispatch(rejected(String(data)));
    }
  } catch (e) {
    dispatch(rejected(String(e)));
  }
}

/**
 * API data sends back an array with different roles.
 * We search for a role that contains OWNER in the array and return accordingly
 * @param roles 
 * @returns 
 */
const isTitulaire = (roles: string[]) => {
  for (let entry of roles) {
    if (entry.toUpperCase().includes("OWNER")) {
      return true;
    }
  }

  return false;
}

/**
 * On globalPage 
 * @param globalFilters 
 * @param storeFilters 
 * @returns 
 */
const areGlobalFiltersEqual = (storeFilters: GlobalPageUsedFilters, globalFilters: GlobalPageUsedFilters) =>
  globalFilters?.startDate === storeFilters?.endDate && globalFilters?.startDate === storeFilters?.startDate && globalFilters?.period === storeFilters?.period;
