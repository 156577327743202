import contents from '../contents.json';
import { setEndDate, setStartDate } from '../features/filters';
import { fetchLine, fetchPie, rejectLine, rejectPie, resolveLine, resolvePie } from '../features/time';
import { selectFilters, selectTimeLineFilters, selectTimeLineStatus, selectTimePieFilters, selectTimePieStatus } from "../utils/selectors";
import { ChartPageLineData, TFilters } from '../utils/types';
import { deepEqualityInOrder, fetchGetData } from '../utils/utils';

export const fetchTimeLineChart = async (dispatch: any, getState: any) => {
  const filters = selectFilters(getState()); // Current filter selection
  const timeLineFilters = selectTimeLineFilters(getState());
  const status = selectTimeLineStatus(getState());

  if (status === "pending" || deepEqualityInOrder(filters, timeLineFilters)) {
    return;
  }
  dispatch(fetchLine());
  const path = contents.paths.time_line;
  try {
    const response = await fetchGetData(path, filters, 'line');
    const data: ChartPageLineData = await response.json();
    if (response.status === 200) {
      dispatch(resolveLine({ filters, data }));
      if (data?.objectiveDate) {
        dispatch(setEndDate(data.objectiveDate?.end));
        dispatch(setStartDate(data.objectiveDate?.start));
      }
    } else {
      dispatch(rejectLine(String(data)));
    }
  } catch (e) {
    dispatch(rejectLine(String(e)))
  }
}

export const fetchTimePieChart = async (dispatch: any, getState: any) => {
  const status = selectTimePieStatus(getState());
  const filters = selectFilters(getState()); // Current filter selection
  const validPieFilters: TFilters = { ...filters, view: "null" }; // view is useless for pie request
  const timePieFilters = selectTimePieFilters(getState());

  if (status === "pending" || deepEqualityInOrder(validPieFilters, timePieFilters)) {
    return;
  }
  dispatch(fetchPie());
  const path = contents.paths.time_pie;
  try {
    const response = await fetchGetData(path, filters, "pie");
    const data = await response.json();
    if (response.status === 200) {
      dispatch(resolvePie({ filters: validPieFilters, data }));
    } else {
      dispatch(rejectPie(String(data)));
    }
  } catch (e) {
    dispatch(rejectPie(String(e)));
  }
}