import styled from '@emotion/styled';
import { Typography } from '@mui/material';
import { Box } from '@mui/system';

export const StyledBoxCard = styled(Box)`
  border-radius: 20px;
  width: 30%;
  min-width: 350px;
  background-color: #f5f7fc;
  padding-top: 9px;
  margin: 20px;
  height: 100%;
`;

export const StyledTitleCard = styled(Typography) <{ color: string }>`
  font-size: 18px;
  @media (min-width: 1024px){
    font-size: 20px;
  }
  font-weight: bold;
  text-align: center;
  color: ${(props) => props.color};
`;

export const StyledHeaderBox = styled(Box) <{ color: string }>`
  height: 32px;
  width: 100%;
  background-color: ${(props) => props.color};
  border-radius: 15px;
`;

export const StyledBoxMain = styled(Box)`
  padding: 9px;
`;
