import { Grid } from "@mui/material";
import { GoalPanel } from "../../components/GoalPanel";
import { LineChart } from "../../components/LineChart";
import { PageTitleRecap } from "../../components/PageTitleRecap";
import { Pies } from "../../components/Pies";
import contents from '../../contents.json';
import { useFetchPatientData } from "../../hooks/fetch/useFetchPatientData";
import { useNavigationUpdate } from "../../hooks/useNavigationUpdate";
import { usePatientsData } from "../../hooks/usePatientsData";

export const PatientsPage = () => {

  const { lineData, lineStatus, pieDatas, pieStatus, keydata } = usePatientsData();
  useNavigationUpdate("patients");
  useFetchPatientData();

  return (
    <>
      <LineChart
        status={lineStatus}
        error={contents.errors.patients.linechart}
        title={contents.lineChart.titles.patients}
        data={lineData}
      />

      <PageTitleRecap
        value={keydata?.current || 0}
        description={contents.recapDescription.patients}
      />

      <Grid container pt={3} >
        <GoalPanel
          real={keydata?.current}
          goal={keydata?.goal}
        />
        <Pies
          status={pieStatus}
          error={contents.errors.patients.piecharts}
          p1title={contents.pieChart.titles.patients_p1}
          p1data={pieDatas?.p1.data}
          p1colors={contents.pieChart.patients.p1}
          p2title={contents.pieChart.titles.patients_p2}
          p2data={pieDatas?.p2.data}
          p2colors={contents.pieChart.patients.p2}
        />
      </Grid>
    </>
  )
}