import { configureStore } from "@reduxjs/toolkit";
import appointmentsReducer from '../features/appointments';
import filtersReducer from "../features/filters";
import globalReducer from "../features/global";
import navigationReducer from '../features/navigation';
import patientsReducer from '../features/patients';
import remunerationReducer from '../features/remuneration';
import timeReducer from '../features/time';

const store = configureStore({
  reducer: {
    global: globalReducer,
    filters: filtersReducer,
    patients: patientsReducer,
    appointments: appointmentsReducer,
    time: timeReducer,
    remuneration: remunerationReducer,
    navigation: navigationReducer
  }
});

export default store;
// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch