import contents from "../../contents.json";
import { StyledLegendCircle, StyledLegendList, StyledLegendListItem } from "./LineChart.styles";

type LegendProps = {
  remuneration?: boolean;
}

export const LineChartLegend = ({ remuneration = false }: LegendProps) => (
  <StyledLegendList>
    <StyledLegendListItem>
      <StyledLegendCircle backColor={contents.lineChart.color.bpm} />
      {contents.lineChart.legend.bpm}
    </StyledLegendListItem>
    <StyledLegendListItem>
      <StyledLegendCircle backColor={contents.lineChart.color.chimio} />
      {contents.lineChart.legend.chimio}
    </StyledLegendListItem>
    {!remuneration && (
      <StyledLegendListItem>
        <StyledLegendCircle backColor={contents.lineChart.color.posology} />
        {contents.lineChart.legend.posology}
      </StyledLegendListItem>
    )}
  </StyledLegendList>
);