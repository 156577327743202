import styled from "@emotion/styled";
import contents from '../../contents.json';
import theme from "../../theme";

export const StyledContainer = styled('div')`
margin: 5px 30px 30px 20px;
`;

export const StyledLegendCircle = styled('div') <{ backColor: string }>`
  width: 10px;
  height: 10px;
  border-radius: 100px;
  background-color: ${props => props.backColor};
  margin-right: 10px;
`;

export const StyledTooltipCircle = styled('div') <{ backColor: string }>`
  width: 10px;
  height: 10px;
  border-radius: 100px;
  background-color: ${props => props.backColor};
  margin-right: 3px;
`;

export const StyledLegendList = styled('ul')`
  display: flex;
  list-style-type: none;
  margin-bottom: 30px;
  padding: 0;
`;

export const StyledLegendListItem = styled('li')`
  display: flex;
  align-items: center; 
  margin: 0;
  margin-right: 30px;
`;

export const StyledTooltipValue = styled('span')`
  margin-right: 5px;
`;

export const StyledTooltipName = styled('span')`
  color: ${contents.lineChart.color.axisStroke}
`;

export const styles = {
  tooltip: {
    wrapperStyle: { boxShadow: '0 5px 15px #44444F1A' },
    itemStyle: { fontWeight: 800 },
    contentStyle: { border: 'none' },
    labelStyle: { fontWeight: 800, color: theme.palette.primary.main, fontSize: 12 },
    itemFormat: { display: 'flex', alignItems: 'center' }
  }
} as const;