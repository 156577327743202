import styled from '@emotion/styled';
import { Box } from '@mui/material';

export const StyledContainerProgressBar = styled(Box)`
  width: 100%;
  height: 6px;
  border-radius: 10px;
  background-color: #dbe1ef;
`;

export const StyledProgressBar = styled(Box) <{ width: number; color: string }>`
  width: ${(props) => props.width}%;
  height: 6px;
  border-radius: 10px;
  background-color: ${(props) => props.color};
`;

export const StyledContainerDescription = styled(Box)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const StyledTitleDescription = styled('p')`
  font-size: 14px;
  @media (min-width: 1024px){
    font-size: 16px;
  }
  font-weight: bold;
  color: #01438f;
  padding: 0;
  margin: 0;
`;

export const StyledDetailsDescription = styled('p')`
  font-size: 12px;
  @media (min-width: 1024px){
    font-size: 14px;
  }
  color: #01438f;
  font-weight: regular;
  padding: 0;
  margin: 0;
`;
export const StyledContainer = styled(Box)`
  height: 52px;
  margin-bottom: 15px;
`;
export const StyledAmount = styled('p') <{ color: string; margin?: number; money: boolean }>`
  font-size: 11px;
  @media (min-width: 1024px){
    font-size: 14px;
  }
  font-weight: bold;
  color: ${(props) => props.color};

  margin-left: ${(props) => (props.money ? props.margin : 0)}%;
  text-align: ${(props) => !props.money && 'right'};
`;
