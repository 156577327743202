import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { changePage } from "../features/navigation";
import { selectNavPage } from "../utils/selectors";
import { AppDispatch } from "../utils/store";

export const useNavigationUpdate = (path: string) => {
  const dispatch = useDispatch<AppDispatch>();
  const navigation = useSelector(selectNavPage);

  useEffect(() => {
    if (navigation !== path) {
      dispatch(changePage(path));
    }
  }, [dispatch, navigation, path])
}