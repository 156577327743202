import styled from '@emotion/styled';
import { Box } from '@mui/material';

export const StyledContainer = styled(Box) <{ color: string }>`
  height: 32px;
  @media (min-width: 1024px){
    padding: 4px 0;
  }
  width: 100%;
  background-color: ${(props) => props.color};
  border-radius: 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const StyledAmountOf = styled('p')`
  font-size: 14px;
  font-weight: bold;
  color: white;
`;
export const StyledAmount = styled('p') <{ color: string }>`
  font-size: 20px;
  font-weight: bold;
  color: ${(props) => props.color};
  background-color: #f5f7fc;
  height: 26px;
  width: 44px;
  text-align: center;
  margin-left: 10px;
`;

export const StyledAmountContainer = styled(Box)`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 0 15px;
  justify-content: space-between;
`;

export const StyledObj = styled('p')`
  font-size: 11px;
  @media (min-width: 1024px){
    font-size: 14px;
  }
  color: white;
  margin-left: 15px;
`;
