import styled from '@emotion/styled';
import { Box } from '@mui/material';

export const StyledContainer = styled(Box) <{ backColor: string }>`
display: flex;
border-radius: 6px;
align-items: center;
width: 190px;
justify-content: space-around;
padding: 5px 10px 5px 20px;
background-color: ${({ backColor }) => backColor};
`;

export const StyledPercentageBox = styled(Box)`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 60px;
  height: 60px;
  background-color: white;
  border-radius: 100px;
`;