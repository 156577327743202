import { GlobalCard } from '../../components/GlobalCard';
import { KeyData } from '../../components/KeyData';
import { Loader } from '../../components/Loader/Loader';
import { RequestError } from '../../components/RequestError/RequestError';
import contents from '../../contents.json';
import { useFetchGlobalData } from '../../hooks/fetch/useFetchGlobalData';
import { useGlobalData } from '../../hooks/useGlobalData';
import { useNavigationUpdate } from '../../hooks/useNavigationUpdate';
import { StyledContainer } from './GlobalPage.styles';

export const GlobalPage = () => {
  useNavigationUpdate("global");
  useFetchGlobalData();
  const { status, chimioData, bpmData } = useGlobalData();
  return (
    <>
      {status === "rejected" && <RequestError error={contents.errors.global} />}
      {status === "pending" && <Loader />}
      {
        status === "resolved" &&
        <>
          <KeyData />
          <StyledContainer sx={{ justifyContent: { xs: 'center', md: 'space-around' } }}>
            <GlobalCard
              color={contents.lineChart.color.bpm}
              title={contents.lineChart.legend.bpm}
              details={true}
              data={bpmData}
            />
            <GlobalCard
              color={contents.lineChart.color.chimio}
              title={contents.lineChart.legend.chimio}
              details={true}
              data={chimioData}
            />
          </StyledContainer>
        </>
      }
    </>
  );
};
