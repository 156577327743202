import { useDispatch, useSelector } from "react-redux";
import { selectFilters, selectTokenFound } from "../../utils/selectors";
import { AppDispatch } from "../../utils/store";

/**
 * Export variables used in all of the requests
 * token allowing to process the request
 * filters allowing to process the query
 * @returns 
 */
export const useBaseQueryVariables = () => {
  const filters = useSelector(selectFilters);
  const tokenFound = useSelector(selectTokenFound);
  const dispatch = useDispatch<AppDispatch>();

  return ({
    filters, tokenFound, dispatch
  });
}