import { useDispatch, useSelector } from "react-redux";
import contents from '../../contents.json';
import { setIsFirstCycle, setPeriod, setTheme } from '../../features/filters';
import { selectFilters, selectNavPage, selectUserHasGoal, selectUserIsTitulaire } from "../../utils/selectors";
import { DateSelector } from "./DateSelector";
import { StyledContainer, StyledInputContainer, StyledLabel } from "./Filters.styles";
import { Select } from "./Select";

export const Filters = () => {

  const dispatch = useDispatch();
  const { period } = useSelector(selectFilters);
  const isTitulaire = useSelector(selectUserIsTitulaire);
  const navigation = useSelector(selectNavPage);
  const hasGoals = useSelector(selectUserHasGoal);

  return (
    <StyledContainer sx={{ overflowX: { xs: 'scroll', md: 'hidden' } }}>
      {(isTitulaire && hasGoals) && (
        <StyledInputContainer>
          <StyledLabel>{contents.filters.period}</StyledLabel>
          <input style={{ cursor: 'pointer' }} type="checkbox" onClick={() => dispatch(setPeriod())} checked={period} />
        </StyledInputContainer>
      )}

      <DateSelector disabled={period && isTitulaire && hasGoals} />
      <Select changefn={setIsFirstCycle} disabled={navigation === "global"} options={contents.filters.cycles} />
      <Select changefn={setTheme} disabled={navigation === "global"} options={contents.filters.themes} />
    </StyledContainer >
  )
}