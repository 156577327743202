import { GoalPanel } from "../../components/GoalPanel";
import { LineChart } from "../../components/LineChart";
import { PageTitleRecap } from "../../components/PageTitleRecap";
import contents from '../../contents.json';
import { useFetchRemunerationData } from "../../hooks/fetch/useFetchRemunerationData";
import { useNavigationUpdate } from "../../hooks/useNavigationUpdate";
import { useRemunerationData } from "../../hooks/useRemunerationData";

export const RemunerationPage = () => {
  useNavigationUpdate("remuneration");
  useFetchRemunerationData();
  const { lineData, lineStatus, keydata } = useRemunerationData();
  return (
    <>
      <LineChart
        error={contents.errors.remuneration}
        status={lineStatus}
        title={contents.lineChart.titles.remuneration}
        data={lineData}
      />

      <PageTitleRecap
        value={`${keydata?.current || 0} €`}
        description={contents.recapDescription.remunerations}
      />

      <GoalPanel
        isRemuneration
        real={keydata?.current}
        goal={keydata?.goal}
      />
    </>
  )
}