import { Grid } from "@mui/material";
import { useSelector } from "react-redux";
import { GoalPanel } from "../../components/GoalPanel";
import { LineChart } from "../../components/LineChart";
import { PageTitleRecap } from "../../components/PageTitleRecap";
import { Pies } from "../../components/Pies";
import contents from '../../contents.json';
import { useFetchTimeData } from "../../hooks/fetch/useFetchTimeData";
import { useNavigationUpdate } from "../../hooks/useNavigationUpdate";
import { useTimeData } from "../../hooks/useTimeData";
import { selectFilters } from "../../utils/selectors";

export const TimeSpentPage = () => {
  useNavigationUpdate("time");
  useFetchTimeData();
  const { lineData, lineStatus, pieDatas, pieStatus, keydata, hours, minutes } = useTimeData();
  const filters = useSelector(selectFilters);
  return (
    <>
      <LineChart
        status={lineStatus}
        title={contents.lineChart.titles.time}
        data={lineData}
        error={contents.errors.time.linechart}
      />

      <PageTitleRecap
        value={`${hours}H${minutes}`}
        description={contents.recapDescription.time}
      />

      <Grid container pt={3}>
        <GoalPanel
          real={keydata?.current}
          goal={keydata?.goal}
          isTime
        />
        <Pies
          status={pieStatus}
          error={contents.errors.time.piecharts}
          p1title={contents.pieChart.titles.time_p1}
          p2title={filters.period ? contents.pieChart.titles.time_p2 : ""}
          p1data={pieDatas?.p1.data}
          p2data={pieDatas?.p2?.data}
          p1colors={contents.pieChart.time.p1}
          p2colors={contents.pieChart.time.p2}
          isTime
        />
      </Grid>
    </>
  )
}