import contents from '../../contents.json';

export const lines = [{
  dataKey: "bpm",
  stroke: contents.lineChart.color.bpm
}, {
  dataKey: "chimio",
  stroke: contents.lineChart.color.chimio
}, {
  dataKey: "posology",
  stroke: contents.lineChart.color.posology
}];
