import { useSelector } from "react-redux";
import { selectTimeGoal, selectTimeLineData, selectTimeLineStatus, selectTimePieData, selectTimePieStatus } from "../utils/selectors";

export const useTimeData = () => {
  const lineData = useSelector(selectTimeLineData);
  const lineStatus = useSelector(selectTimeLineStatus);
  const pieDatas = useSelector(selectTimePieData);
  const pieStatus = useSelector(selectTimePieStatus);
  const keydata = useSelector(selectTimeGoal);
  const hours = Math.trunc(keydata?.current / 60) || 0;
  const minutes = keydata?.current % 60 || '00';

  return ({
    lineData, lineStatus, pieDatas, pieStatus, keydata, hours, minutes
  });
}