import styled from "@emotion/styled";
import { Box } from "@mui/material";
import { Link } from "react-router-dom";
import theme from "../../theme";
export const StyledContainer = styled(Box)`
  display: flex;
  padding-bottom: 15px;
`;

export const StyledInputContainer = styled(Box)`
  display: flex;
  align-items: center;
  background-color: #EEF1F9;
  padding: 3px 10px;
  border-radius: 16px; 
  margin-right: 30px;
  color: theme.palette.primary.main;
  font-weight: 800;
`;

export const StyledSelect = styled('select')`
  padding-right: 30px;
  background-color: transparent;
  border: none;
  color: ${theme.palette.primary.main};
  font-weight:800;
  cursor:pointer;
  &:disabled {
    color: #a3a3a3;
    cursor: unset;
  }
`;

export const StyledPickerModalButton = styled('button')`
  background-color: transparent;
  border: none;
  width: 255px;
  font-weight: 800;
  color: ${theme.palette.primary.main};
  cursor: pointer;
  &:disabled{
    cursor: unset;
    color: #a3a3a3;
  }
  `;

// This is bad -- TODO find why font is different on elements
export const StyledLabel = styled('button')`
  font-weight: 800;
  color: ${theme.palette.primary.main};
  background-color: transparent;
  border: none;
  margin-right: 30px;
`;

export const StyledLink = styled(Link)`
  text-decoration: none;
  background-color: ${theme.palette.secondary.main};
  padding: 5px 10px;
  margin-right: 20px;
  color: white;
  border-radius: 16px;
  font-size: 11px;
`;