import { Box } from '@mui/material';
import React from 'react';

type Props = {
  children: React.ReactNode;
  index: number;
  value: number;
};

export const TabPanel = (props: Props) => {
  return (
    <div role="tabpanel" hidden={props.value !== props.index} id={`simple-tabpanel-${props.index}`} aria-labelledby={`simple-tab-${props.index}`}>
      {props.value === props.index && (
        <Box >
          {props.children}
        </Box>
      )}
    </div>
  );
};
