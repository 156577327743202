//create nav style reactjs ts
import styled from '@emotion/styled';

export const StyledMain = styled('main')`
  display: flex;
  border-top: 1px solid #dbe1ef;
`;

export const StyledSection = styled('section')`
  width: 100%;
`;