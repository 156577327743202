import { createSlice } from "@reduxjs/toolkit";
import { BooleanAction, SingleStringAction, TFilters } from "../utils/types";

const yesterday = new Date();
yesterday.setDate(yesterday.getDate() - 1);

const initialState: TFilters = {
  period: true,
  isFirstCycle: null,
  theme: "all",
  startDate: `${yesterday.getFullYear()}-01-01`, // special case handle for yesterday = 31st December 
  endDate: `${yesterday.toISOString().split('T')[0]}`,
  view: "month", // For linechart views
}

const { actions, reducer } = createSlice({
  name: "filters",
  initialState,
  reducers: {
    setStartDate: (draft, action: SingleStringAction) => {
      draft.startDate = action.payload;
      return;
    },
    setEndDate: (draft, action: SingleStringAction) => {
      draft.endDate = action.payload;
      return;
    },
    setTheme: (draft, action: SingleStringAction) => {
      draft.theme = action.payload;
    },
    setPeriod: (draft) => {
      draft.period = !draft.period;
      return;
    },
    setIsFirstCycle: (draft, action: BooleanAction) => {
      draft.isFirstCycle = action.payload;
      return;
    },
    setView: (draft, action) => {
      if (action.payload === draft.view) {
        return;
      }
      draft.view = action.payload;
      return;
    }
  }
})

export const { setPeriod, setStartDate, setEndDate, setTheme, setIsFirstCycle, setView } = actions;
export default reducer;