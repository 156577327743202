import { createSlice } from "@reduxjs/toolkit";
import { ChartPageLineData, TFilters } from "../utils/types";

type state = {
  line_status: string;
  line_error: string | null;
  line_data: ChartPageLineData | null;
  line_filters: {};
}

const initialState: state = {
  line_status: "void",
  line_error: null,
  line_data: null,
  line_filters: {},
}

const { actions, reducer } = createSlice({
  name: "remuneration",
  initialState,
  reducers: {
    fetchLine: (draft) => {
      if (draft.line_status === 'pending') {
        return;
      }
      draft.line_status = "pending";
      draft.line_error = null;
      return;
    },
    resolveLine: (draft, action: { type: string, payload: { filters: TFilters, data: ChartPageLineData } }) => {
      draft.line_status = "resolved";
      draft.line_data = action.payload.data;
      draft.line_filters = action.payload.filters;
      return;
    },
    rejectLine: (draft, action) => {
      draft.line_status = "rejected";
      draft.line_data = null;
      draft.line_filters = {};
      draft.line_error = action.payload;
      return;
    }
  }
})

export const { fetchLine, resolveLine, rejectLine } = actions;
export default reducer;