import store from "./store";
import { GlobalData, LineData, TFilters } from "./types";

/**
 * Compares two objects by transforming them into strings and comparing them.
 * This means the objects must have the same key order in order to be equal.
 * This function is used here for filter deep comparison, keys being sent in same order
 * @param a - object with key/value pairs
 * @param b - object to compare
 * @returns
 */
export const deepEqualityInOrder: (a: object, b: object) => boolean = (a: object, b: object) => JSON.stringify(a) === JSON.stringify(b);

/**
 *
 * @param path - api path to fetch
 * @param filters - filters used to format the query
 * @param type - request type, depending on page
 * @returns
 */
export const fetchGetData = async (path: string, filters: TFilters, type: string) => {
  const query = getQuery(filters, type);
  return fetch(`${process.env.REACT_APP_API_URL}/dashboard/${path}?${query}`, {
    credentials: 'include',
    headers: {
      Authorization: `Bearer ${localStorage.getItem('token')}`,
    },
  });
};

/**
 * Format query based on request type
 * @param filters - Current filter state
 * @param type - Request need
 * @returns
 */
export const getQuery = (filters: TFilters, type: string) => {
  const { isTitulaire, hasGoal } = store.getState().global.data as GlobalData;
  const canShowPeriod = isTitulaire && hasGoal && filters.period;

  switch (type) {
    case "global":
      return `start=${filters.startDate}&end=${filters.endDate}&period=${canShowPeriod}`;
    case "pie":
      return `start=${filters.startDate}&end=${filters.endDate}&period=${canShowPeriod}&theme=${filters.theme}&isFirstCycle=${filters.isFirstCycle}`;
    case "line":
      return `start=${filters.startDate}&end=${filters.endDate}&period=${canShowPeriod}&theme=${filters.theme}&isFirstCycle=${filters.isFirstCycle}&view=${filters.view}`
    default:
      return '';
  }
};

/**
 * Line Chart unique value counter.
 * Used to determine number of ticks that will be printed on screen
 * @param d - data for the linechart
 * @returns
 */
export const countUniqueValues = (d: LineData[]) => {
  let found: any = {};
  d &&
    d.forEach((val) => {
      const bpm = val?.bpm || 0;
      const chimio = val?.chimio || 0;
      found[bpm]++;
      found[chimio]++;
    });
  return Object.keys(found).length;
};

/**
 * Format hour to have HHh.. format
 * @param val
 * @returns
 */
export const hourformat = (val: number) => {
  const format = Math.trunc(val / 60);
  return format < 10 ? `0${format}` : format;
};

/**
 * format minutes to have ..hMM format
 * @param val
 * @returns
 */
export const minuteformat = (val: number) => {
  const format = val % 60;
  return format < 10 ? `0${format}` : format;
};
