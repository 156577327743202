import { useState } from "react";
import { useSelector } from "react-redux";
import { AppointmentsPage } from "../../pages/AppointmentsPage";
import { GlobalPage } from "../../pages/GlobalPage";
import { PatientsPage } from "../../pages/PatientsPage";
import { RemunerationPage } from "../../pages/RemunerationPage";
import { TimeSpentPage } from "../../pages/TimeSpentPage";
import { selectUserIsTitulaire } from "../../utils/selectors";
import { Filters } from '../Filters';
import { TabPanel } from "../TabPanel/TabPanel";
import { VerticalTab } from "../VerticalTab/VerticalTab";
import { StyledMain, StyledSection } from "./Dashboard.styles";

export const Dashboard = () => {

  const [value, setValue] = useState(0);
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const isTitulaire = useSelector(selectUserIsTitulaire);
  return (
    <>
      <Filters />
      <StyledMain id="main">
        <VerticalTab value={value} handleChange={handleChange} />
        <StyledSection>
          <TabPanel value={value} index={0}>
            <GlobalPage />
          </TabPanel>
          <TabPanel value={value} index={1}>
            <PatientsPage />
          </TabPanel>
          <TabPanel value={value} index={2}>
            <AppointmentsPage />
          </TabPanel>
          <TabPanel value={value} index={3}>
            {isTitulaire ?
              <RemunerationPage />
              :
              <TimeSpentPage />
            }
          </TabPanel>
          {isTitulaire && (
            <TabPanel value={value} index={4}>
              <TimeSpentPage />
            </TabPanel>
          )}
        </StyledSection>
      </StyledMain>
    </>
  )
}