import { ThemeProvider } from '@mui/material';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Dashboard } from './components/Dashboard';
import { setTokenFound } from './features/navigation';
import theme from './theme';

function App() {

  const dispatch = useDispatch();
  useEffect(() => {
    //TODO -- REMOVE THIS in prod
    if (localStorage.getItem("token")) dispatch(setTokenFound(true));

    const param = new URLSearchParams(window.location.search).get("token");
    if (param) {
      localStorage.setItem("token", param);
      dispatch(setTokenFound(true));
    } else {
      // window.parent.location.href = "/login"; // PROD ONLY
    }
  }, [dispatch])

  return (
    <ThemeProvider theme={theme}>
      <Dashboard />
    </ThemeProvider>
  );
}

export default App;
