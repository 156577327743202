import { useSelector } from "react-redux";
import { selectAppointmentsGoal, selectAppointmentsLineData, selectAppointmentsLineStatus, selectAppointmentsPieData, selectAppointmentsPieStatus } from "../utils/selectors";

export const useAppointmentData = () => {
  const lineData = useSelector(selectAppointmentsLineData);
  const lineStatus = useSelector(selectAppointmentsLineStatus);
  const pieDatas = useSelector(selectAppointmentsPieData);
  const pieStatus = useSelector(selectAppointmentsPieStatus);
  const keydata = useSelector(selectAppointmentsGoal);

  return ({
    lineData, lineStatus, pieDatas, pieStatus, keydata
  });
}