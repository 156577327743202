import styled from "@emotion/styled";

export const StyledPieContainer = styled('div')`
  text-align: center; 
  margin-right: 50px;
  margin-left: 50px;
  margin-bottom: 50px
`;

export const StyledLegendList = styled('ul')`
  list-style-type: none;
  text-align: left;
  padding: 0;
`;

export const StyledLegendListItem = styled('li')`
  display: flex;
  align-items: center;
  margin-bottom: 5px;
  justify-content: space-between;
`;

export const StyledLegendSquare = styled('div') <{ backColor: string }>`
  min-width: 15px; 
  width: 15px;
  height: 15px;
  border-radius: 3px;
  margin-right: 20px;
  background-color: ${props => props.backColor};
`;

export const StyledLegendItemContainer = styled('div')`
  display: flex;
`;