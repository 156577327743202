import { useEffect, useState } from "react";

export const useResizeTracker = () => {
  const [size, setSize] = useState(0);

  useEffect(() => {
    window.addEventListener('resize', () => {
      setSize(window.innerWidth);
    });
    window.removeEventListener("resize", function () { });
  }, []);

  return size;
}