import { StyledAmount, StyledAmountContainer, StyledAmountOf, StyledContainer, StyledObj } from './CardHeader.styles';

type Props = { color: string; number: number; details: boolean };

export const CardHeader = ({ color, number, details }: Props) => {
  return (
    <StyledContainer color={color}>
      {details && (
        <>
          <StyledObj>Objectif fixe</StyledObj>
          <StyledAmountContainer>
            <StyledAmountOf>Nombre de patients</StyledAmountOf>
            <StyledAmount color={color}>{number}</StyledAmount>
          </StyledAmountContainer>
        </>
      )}
    </StyledContainer>
  );
};
