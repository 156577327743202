import { StyledAmount, StyledContainer, StyledContainerDescription, StyledContainerProgressBar, StyledDetailsDescription, StyledProgressBar, StyledTitleDescription } from './ProgressBar.styles';
type Props = {
  color: string;
  amount: number;
  title: string;
  total: number;
  money?: boolean;
  amountOf: string;
  hasGoal?: boolean;
};

export const ProgressBar = ({ color, amount, title, total, money = false, amountOf, hasGoal }: Props) => {
  const percent = Math.min(100, (amount / total) * 100);
  const maxTextAlign = Math.min(90, percent);
  return (
    <StyledContainer>
      <StyledContainerDescription>
        <StyledTitleDescription>{title}</StyledTitleDescription>
        <StyledDetailsDescription>
          {hasGoal ? total : '-'} {money && ' €'}
        </StyledDetailsDescription>
      </StyledContainerDescription>
      <StyledContainerProgressBar color={color}>
        <StyledProgressBar color={color} width={hasGoal ? percent : 100} />
      </StyledContainerProgressBar>
      <StyledAmount color={color} money={Boolean(hasGoal && money)} margin={hasGoal ? maxTextAlign : 100}>
        {amount} {amountOf}
      </StyledAmount>
    </StyledContainer>
  );
};
