import { Grid } from "@mui/material";
import { useSelector } from "react-redux";
import { GoalPanel } from "../../components/GoalPanel";
import { LineChart } from "../../components/LineChart";
import { PageTitleRecap } from "../../components/PageTitleRecap";
import { Pies } from "../../components/Pies";
import contents from '../../contents.json';
import { useFetchAppointmentsData } from "../../hooks/fetch/useFetchAppointmentsData";
import { useAppointmentData } from "../../hooks/useAppointmentData";
import { useNavigationUpdate } from "../../hooks/useNavigationUpdate";
import { selectFilters } from "../../utils/selectors";

export const AppointmentsPage = () => {
  useNavigationUpdate("appointments");
  useFetchAppointmentsData();
  const filters = useSelector(selectFilters);
  const { lineData, lineStatus, pieDatas, pieStatus, keydata } = useAppointmentData();
  const p1Color = filters.period ? contents.pieChart.appointments.p1_alt : contents.pieChart.appointments.p1;
  const title_plural = filters.period ? contents.recapDescription.appointments : contents.recapDescription.appointments_done;
  const title_single = filters.period ? contents.recapDescription.appointment : contents.recapDescription.appointment_done;
  const selectedTitle = keydata?.current > 1 ? title_plural : title_single;
  return (
    <>
      <LineChart
        title={contents.lineChart.titles.appointments}
        data={lineData}
        error={contents.errors.appointments.linechart}
        status={lineStatus}
      />

      <PageTitleRecap
        value={keydata?.current || 0}
        description={selectedTitle}
      />

      <Grid container pt={3}>
        <GoalPanel
          real={keydata?.current}
          goal={keydata?.goal}
        />
        <Pies
          status={pieStatus}
          error={contents.errors.appointments.piecharts}
          p1title={contents.pieChart.titles.appointments_p1}
          p2title={contents.pieChart.titles.appointments_p2}
          p1data={pieDatas?.p1.data}
          p2data={pieDatas?.p2.data}
          p1colors={p1Color}
          p2colors={contents.pieChart.appointments.p2}
        />
      </Grid>
    </>
  )
}