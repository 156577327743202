import { createTheme } from '@mui/material';
import { Shadows } from '@mui/material/styles/shadows';

const theme = createTheme({
  shadows: Array(25).fill('none') as Shadows,
  typography: {
    fontFamily: 'Poppins',
    fontSize: 11,
    h2: {
      fontSize: 18,
      fontWeight: 900,
    }
  },
  palette: {
    primary: { main: '#01438F' },
    secondary: { main: '#4585F8' },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: 999,
          textTransform: 'initial',
        },
      },
    },
  },
});

export default theme;
