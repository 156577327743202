import { useEffect } from "react";
import { fetchTimeLineChart, fetchTimePieChart } from "../../services/time";
import { useBaseQueryVariables } from "./useBaseQueryVariables";

export const useFetchTimeData = () => {
  const { filters, tokenFound, dispatch } = useBaseQueryVariables();

  useEffect(() => {
    if (tokenFound) {
      dispatch(fetchTimeLineChart);
      dispatch(fetchTimePieChart);
    }
  }, [dispatch, filters, tokenFound]);
}