import contents from '../contents.json';
import { setEndDate, setStartDate } from '../features/filters';
import { fetchLine, rejectLine, resolveLine } from '../features/remuneration';
import { selectFilters, selectRemunerationLineFilters, selectRemunerationLineStatus } from "../utils/selectors";
import { ChartPageLineData } from '../utils/types';
import { deepEqualityInOrder, fetchGetData } from '../utils/utils';

export const fetchRemunerationLineChart = async (dispatch: any, getState: any) => {
  const filters = selectFilters(getState()); // Current filter selection
  const remunerationLineFilters = selectRemunerationLineFilters(getState());
  const status = selectRemunerationLineStatus(getState());

  if (status === "pending" || deepEqualityInOrder(filters, remunerationLineFilters)) {
    return;
  }
  dispatch(fetchLine());
  const path = contents.paths.remuneration;
  try {
    const response = await fetchGetData(path, filters, "line");
    const data: ChartPageLineData = await response.json();
    if (response.status === 200) {
      dispatch(resolveLine({ filters, data }));
      if (data?.objectiveDate) {
        dispatch(setEndDate(data.objectiveDate?.end));
        dispatch(setStartDate(data.objectiveDate?.start));
      }
    } else {
      dispatch(rejectLine(String(data)));
    }
  } catch (e) {
    dispatch(rejectLine(String(e)))
  }
}