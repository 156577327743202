import { useSelector } from "react-redux";
import { selectPatientsGoal, selectPatientsLineData, selectPatientsPieData, selectPatientsPieStatus, selectPatientstLineStatus } from "../utils/selectors";

export const usePatientsData = () => {
  const lineData = useSelector(selectPatientsLineData);
  const lineStatus = useSelector(selectPatientstLineStatus);
  const pieDatas = useSelector(selectPatientsPieData);
  const pieStatus = useSelector(selectPatientsPieStatus);
  const keydata = useSelector(selectPatientsGoal);

  return ({
    lineData, lineStatus, pieDatas, pieStatus, keydata
  });
}