import { Typography } from '@mui/material';
import TextField from '@mui/material/TextField';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { StaticDatePicker as MUIStaticDatePicker } from '@mui/x-date-pickers/StaticDatePicker';
import frLocale from 'date-fns/locale/fr';
import { useState } from 'react';

type PickerProps = {
  isMin?: boolean;
  change: any;
  title: string;
  min?: Date | null;
  max?: Date | null;
  val: Date;
}

export const StaticDatePicker = ({ isMin = false, min, max, change, title, val }: PickerProps) => {
  const [value, setValue] = useState<Date | null>(val);
  const validMin = min && min?.toString() !== 'Invalid Date';
  const validMax = max && max?.toString() !== "Invalid Date";

  return (
    <>
      <Typography color="primary" fontWeight={900} mt={3} pl={3}>{title}</Typography>
      <LocalizationProvider dateAdapter={AdapterDateFns} locale={frLocale}>
        <MUIStaticDatePicker
          minDate={validMin ? min : undefined}
          maxDate={validMax ? max : undefined}
          displayStaticWrapperAs="desktop"
          mask='__/__/____'
          value={value}
          onChange={(newValue: Date | null) => {
            setValue(newValue);
            change(newValue);
          }}
          renderInput={(params) => <TextField {...params} />}
        />
      </LocalizationProvider>
    </>
  );
}