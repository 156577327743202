import { Typography } from "@mui/material";
import { StyledNumber } from './PageTitleRecap.styles';

type TitleProps = {
  value: number | string;
  description: string;
}

export const PageTitleRecap = ({ value, description }: TitleProps) => (
  <Typography component="h2" variant="h2" mb={2} ml={3} color="primary">
    <StyledNumber>{value}</StyledNumber> {description}
  </Typography>
)