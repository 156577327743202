import styled from "@emotion/styled";

export const StyledButton = styled('button') <{ bgColor: string }>`
width: 120px;
padding: 7px 0;
background-color: ${props => props.bgColor};
border: none;
border-radius: 46px;
margin-bottom: 10px;
margin-right: 10px;
cursor: pointer;
&:hover{
  filter: brightness(90%);
}
`;