import { useState } from 'react';
import { useSelector } from "react-redux";
import { selectFilters } from "../../utils/selectors";
import { StyledInputContainer, StyledPickerModalButton } from "./Filters.styles";
import { PickersModal } from "./PickersModal";

type DateSelectorProps = {
  disabled?: boolean;
}

export const DateSelector = ({ disabled = false }: DateSelectorProps) => {
  const { startDate, endDate } = useSelector(selectFilters);
  const formatStart = new Date(startDate).toLocaleDateString('fr');
  const formatEnd = new Date(endDate).toLocaleDateString('fr');
  const [open, setOpen] = useState(false);

  return (
    <StyledInputContainer>
      <StyledPickerModalButton disabled={disabled} onClick={() => !disabled && setOpen(true)}>
        Du {formatStart} au {formatEnd}
      </StyledPickerModalButton>
      {open && <PickersModal setOpen={setOpen} open={open} />}
    </StyledInputContainer>
  )
}