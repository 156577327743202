import { Grid } from "@mui/material";
import { useSelector } from "react-redux";
import { selectUserIsTitulaire } from "../../utils/selectors";
import { PieData } from "../../utils/types";
import { Loader } from "../Loader/Loader";
import { PieChart } from "../PieChart";
import { RequestError } from "../RequestError/RequestError";

type PiesProps = {
  status: string;
  error: string;
  p1data: PieData[];
  p2data: PieData[];
  p1colors: string[];
  p2colors: string[];
  p1title?: string;
  p2title?: string;
  isTime?: boolean;
}

export const Pies = ({ status, error, p1data, p2data, p1colors, p2colors, p1title, p2title, isTime = false }: PiesProps) => {

  const isTitulaire = useSelector(selectUserIsTitulaire);

  if (status === "pending") {
    return <Grid item xs={12}><Loader /></Grid>
  }

  if (status === "rejected") {
    return <RequestError error={error} />
  }

  return (
    <>
      <Grid item xs={12} md={isTitulaire ? 4 : 6}>
        <PieChart title={p1title} data={p1data} colors={p1colors} isTime={isTime} />
      </Grid>
      <Grid item xs={12} md={isTitulaire ? 4 : 6}>
        <PieChart title={p2title} data={p2data} colors={p2colors} isTime={isTime} />
      </Grid>
    </>
  )
}
