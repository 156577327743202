import { Typography } from "@mui/material";
import contents from '../../contents.json';
import { hourformat, minuteformat } from "../../utils/utils";
import { StyledLegendItemContainer, StyledLegendList, StyledLegendListItem, StyledLegendSquare } from "./PieChart.styles";

type PieChartLegendProps = {
  data: any;
  colors: string[];
  isTime?: boolean;
}

export const PieChartLegend = ({ data, colors, isTime = false }: PieChartLegendProps) => (
  <StyledLegendList>
    {(data || []).map((entry: any, index: any) => (
      <StyledLegendListItem key={`item-${index}`}>
        <StyledLegendItemContainer>
          <StyledLegendSquare backColor={data[index]?.color || colors[index]} />
          <Typography fontSize={12} color={contents.pieChart.color.legendName} mr={2}>{entry.name}</Typography>
        </StyledLegendItemContainer>
        <Typography fontSize={14} fontWeight={900} color='primary'>
          {isTime ? `${hourformat(entry.value)}h${minuteformat(entry.value)}` : entry.value}{isTime ? '' : '%'}
        </Typography>
      </StyledLegendListItem>
    ))}
  </StyledLegendList>
);
