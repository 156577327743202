import { useEffect } from "react";
import { fetchRemunerationLineChart } from "../../services/remuneration";
import { useBaseQueryVariables } from "./useBaseQueryVariables";

export const useFetchRemunerationData = () => {
  const { filters, tokenFound, dispatch } = useBaseQueryVariables();

  useEffect(() => {
    if (tokenFound) {
      dispatch(fetchRemunerationLineChart);
    }
  }, [dispatch, filters, tokenFound]);
}