import { useSelector } from "react-redux";
import { selectGlobalDataBPM, selectGlobalDataChimio, selectGlobalStatus } from "../utils/selectors";

export const useGlobalData = () => {
  const bpmData = useSelector(selectGlobalDataBPM);
  const chimioData = useSelector(selectGlobalDataChimio);
  const status = useSelector(selectGlobalStatus);

  return ({
    bpmData, chimioData, status
  });
}