import { Box, Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import contents from '../../contents.json';
import { setView } from "../../features/filters";
import theme from "../../theme";
import { selectFilters } from "../../utils/selectors";
import { StyledButton } from "./LineChartViewSelector.styles";

export const LineChartViewSelector = () => {

  const dispatch = useDispatch();
  const { view } = useSelector(selectFilters);

  const firstBtnBack = view === "month" ? theme.palette.primary.main : contents.filters.unselected_view_btn;
  const firstBtnColor = view === "month" ? "white" : "primary";
  const secondBtnBack = view === "week" ? theme.palette.primary.main : contents.filters.unselected_view_btn;
  const secondBtnColor = view === "week" ? "white" : "primary";

  return (
    <Box sx={{ alignItems: { xs: 'start', md: 'end' }, display: 'flex', flexDirection: { xs: 'row', md: 'column' }, position: { xs: 'unset', md: 'absolute' }, right: 0, top: 30, zIndex: 10 }}>
      <StyledButton bgColor={firstBtnBack} onClick={() => dispatch(setView("month"))}  >
        <Typography fontWeight={700} fontSize={12} color={firstBtnColor}>{contents.filters.month}</Typography>
      </StyledButton>
      <StyledButton bgColor={secondBtnBack} onClick={() => dispatch(setView("week"))} >
        <Typography fontWeight={700} fontSize={12} color={secondBtnColor}>{contents.filters.week}</Typography>
      </StyledButton>
    </Box>
  )
}