import { Grid, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { useSelector } from "react-redux";
import contents from '../../contents.json';
import theme from "../../theme";
import { selectFilters, selectUserHasGoal, selectUserIsTitulaire } from "../../utils/selectors";
import { StyledContainer, StyledPercentageBox } from "./GoalPanel.styles";

type GoalPanelProps = {
  real: number; // As this is only rendered when user has explicitly asked for goals, always be present
  goal: number; // idem
  isTime?: boolean; // Time spent page has some differences with other pages
  isRemuneration?: boolean;
}

const GoalContainer = ({ isRemuneration, children }: any) => {
  return (
    isRemuneration ?
      <Box pt={3} pl={3}>{children}</Box>
      :
      <Grid item xs={12} md={4} lg={3} display="flex" justifyContent={{ xs: 'center' }} sx={{ height: 'fit-content' }}>
        {children}
      </Grid>
  )
}

//TODO -- remove hardcoded color
export const GoalPanel = ({ real, goal, isTime = false, isRemuneration = false }: GoalPanelProps) => {

  const { period } = useSelector(selectFilters);
  const backColor = theme.palette.secondary.main;
  const real_hours = isTime ? Math.trunc(real / 60) : "";
  const real_minutes = isTime ? real % 60 : "";
  const goal_real_hours = isTime ? Math.trunc(goal / 60) : "";
  const goal_real_minutes = isTime ? goal % 60 : "";
  const timeGoal = isTime ? `${real_hours}h${real_minutes}/${goal_real_hours}h${goal_real_minutes}` : "";
  const isTitulaire = useSelector(selectUserIsTitulaire);
  const userHasGoal = useSelector(selectUserHasGoal);
  const shouldBeRendered = isTitulaire && userHasGoal && period;

  return (
    <>
      {shouldBeRendered && (
        <GoalContainer isRemuneration={isRemuneration}>
          <StyledContainer backColor={backColor} mb={3}>
            <Box>
              <Typography component="h3" fontSize={16} fontWeight={700} color="primary">
                {contents.goalPanel}
              </Typography>
              <Typography color="white" mt={2} mb={1} fontSize={16} fontWeight={700}>
                {isTime ? timeGoal : `${real}/${goal}`}
              </Typography>
            </Box>

            <StyledPercentageBox>
              <Typography color="secondary" fontSize={16} fontWeight={700}>
                {Math.trunc(real / (goal !== 0 ? goal : 1) * 100)}%
              </Typography>
            </StyledPercentageBox>
          </StyledContainer>
        </GoalContainer>
      )}
    </>
  )
}